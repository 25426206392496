import { createGlobalStyle } from 'styled-components';
import '@abc-intelligence/font-vag-rounded-next';
import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 62.5%; // 10px/16px = 62.5% -> 1rem = 10px
  }
  
  html,
  body,
  #root,
  #root > div {
    min-height: 100vh;
  }
  
  body {
    margin: 0;
    font-family: 'Roboto';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

      & *,
      & *::after,
      & *::before{
            margin: 0;
            padding: 0;
            box-sizing: border-box;
          }
  }
`;

export default GlobalStyle;
