import { Suspense, lazy } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { MaintenancePage } from '@abc-intelligence/abc-ui';

import { FrontPageProvider } from 'pages/FrontPage/context/FrontpageContext';
import { Layout } from 'components';

const FrontPage = lazy(() => import('pages/FrontPage'));
const Dashboard = lazy(() => import('pages/Dashboard'));
const Documentation = lazy(() => import('pages/Documentation'));
const Page403 = lazy(() => import('pages/Page403/Page403'));
const Page404 = lazy(() => import('pages/Page404/Page404'));

const Routes = () => (
  <Suspense fallback={<></>}>
    <Switch>
      <Route path={'/'} exact render={() => <Redirect to="/home" />} />
      <Route
        path={'/home'}
        render={() => (
          <Layout>
            <FrontPageProvider>
              <FrontPage />
            </FrontPageProvider>
          </Layout>
        )}
      />
      <Route
        path={'/consult/:id/dashboard'}
        render={() => (
          <Layout>
            <Dashboard />
          </Layout>
        )}
      />
      <Route
        path={'/403'}
        render={() => (
          <Layout>
            <Page403 />
          </Layout>
        )}
      />
      <Route
        path={'/404'}
        render={() => (
          <Layout>
            <Page404 />
          </Layout>
        )}
      />
      <Route path={'/maintenance'} render={() => <MaintenancePage />} />
      <Route
        path={'/documentation/:clinicId/:ruleOutName/:animalSpecies'}
        render={() => (
          <Layout>
            <Documentation />
          </Layout>
        )}
      />
      <Redirect to="/404" />
    </Switch>
  </Suspense>
);

export default Routes;
