import React from 'react';
import styled from 'styled-components';
import { InfoIcon } from '@abc-intelligence/abc-ui';

import { CustomTooltip } from 'components';
import { ToolTipProps } from 'components/CustomTooltip/CustomTooltip';

const InfoMarkIcon = styled(InfoIcon)`
  cursor: pointer;
  color: ${(p) =>
    p.type === 'danger'
      ? p.theme.colors.secondaryRed
      : p.type === 'warning'
      ? p.theme.colors.secondaryOrange
      : p.theme.colors.secondaryBlue};
`;

const NewInfoMark: React.FC<ToolTipProps> = ({
  id,
  textColor,
  type,
  message,
  direction,
  className,
  disable,
}) => {
  return (
    <>
      {message ? (
        <>
          <InfoMarkIcon data-tip data-for={`${id}-infomark`} type={type} />
          <CustomTooltip
            id={`${id}-infomark`}
            textColor={textColor}
            type={type}
            message={message}
            direction={direction || 'right'}
            className={className}
            disable={disable}
          ></CustomTooltip>
        </>
      ) : (
        <CustomTooltip
          id={id}
          textColor={textColor}
          type={type}
          message={message}
          direction={direction || 'right'}
          className={className}
          disable={message ? false : true}
        ></CustomTooltip>
      )}
    </>
  );
};

export default NewInfoMark;
