import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useFlags, withLDProvider } from 'launchdarkly-react-client-sdk';

import Routes from 'base/routes';
import { envConfig } from 'base/config';
import { navigateToMaintenancePage } from 'utils';

function App() {
  const { maintenancePg } = useFlags();

  useEffect(() => {
    if (
      maintenancePg &&
      !['/maintenance', '/maintenance/'].includes(window.location.pathname)
    ) {
      navigateToMaintenancePage();
    }
  }, [maintenancePg]);

  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
}

export default withLDProvider({
  clientSideID: envConfig.ABC_LAUNCH_DARKLY_CLIENT_SIDE_ID,
  context: {
    kind: 'web',
    key: `decisioning-${envConfig.APP_STAGE}`,
    anonymous: true,
  },
})(App);
