import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { ErrorBoundary } from '@abc-intelligence/abc-ui';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-phone-number-input/style.css';

import { envConfig } from 'base/config';
import { store } from './base/store';
import GlobalStyle from 'base/styles/globalStyle';
import i18n from 'base/lang/i18n';
import App from './App';
import theme from 'base/styles/theme';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ErrorBoundary
        homepageLink={envConfig.SIGN_IN_URL}
        lang={i18n.language as 'en' | 'fr'}
      >
        <Provider store={store}>
          <App />
        </Provider>
      </ErrorBoundary>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
