import axios, { AxiosRequestConfig, AxiosPromise, AxiosError } from 'axios';
import { navigateToSignIn, navigateTo403, navigateTo404 } from 'utils';
import { store } from 'base/store';
import { envConfig } from 'base/config';

export const createAxiosInstance = (siteId?: string) => {
  return axios.create({
    baseURL: envConfig.BASE_EMR,
    headers: siteId
      ? {
          'abc-emr-clinic-id': siteId,
        }
      : {},
    withCredentials: true,
  });
};

export const axiosRequest = (
  requests: AxiosRequestConfig[] | AxiosRequestConfig
) => {
  const state = store.getState();
  const userSiteId = state.user?.user_default_clinic_id?.toString();
  const axiosInstance = createAxiosInstance(userSiteId);
  const promises: AxiosPromise<any>[] = [];
  if (Array.isArray(requests)) {
    requests.forEach((request) => {
      const promise = axiosInstance({
        ...request,
      });
      promises.push(promise);
    });
  } else {
    promises.push(axiosInstance({ ...requests }));
  }

  return Promise.all(promises)
    .then((res) => {
      const response = Array.isArray(requests)
        ? res.map((r) => r.data)
        : res[0].data;
      return response;
    })
    .catch((error: AxiosError) => {
      switch (error.response?.status) {
        case 401:
          navigateToSignIn();
          break;
        case 403:
          navigateTo403();
          break;
        case 404:
          navigateTo404();
          break;
        default:
          throw error;
      }
    });
};
