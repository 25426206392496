import styled from 'styled-components';
import {
  DeleteIcon as DefaultDeleteIcon,
  TrashIcon as DefaultTrashIcon,
} from '@abc-intelligence/abc-ui';

const DeleteIcon = styled(DefaultDeleteIcon)`
  ${({ theme }) => theme.mixin.DeleteItemCSS}
`;

const TrashIcon = styled(DefaultTrashIcon)`
  ${({ theme }) => theme.mixin.DeleteItemCSS}
`;

export { DeleteIcon, TrashIcon };
