import { APP_KEYS, PHMainMenuProps } from '@abc-intelligence/abc-ui';

import { envConfig } from 'base/config';
import { t } from 'base/lang/i18n';

export const APP_OPTIONS = [
  {
    appName: APP_KEYS.RESEARCH_GENUS,
    label: t('navbar.appMenu.researchGenus'),
    url: envConfig.RESEARCH_GENUS_URL,
  },
  {
    appName: APP_KEYS.OPS_GENUS,
    label: t('navbar.appMenu.opsGenus'),
    url: envConfig.OPS_GENUS_URL,
  },
  {
    appName: APP_KEYS.MARKET_GENUS,
    label: t('navbar.appMenu.marketGenus'),
    url: envConfig.MARKET_GENUS_URL,
  },
  {
    appName: APP_KEYS.PET_GENUS,
    label: t('navbar.appMenu.petGenus'),
    url: envConfig.PET_GENUS_URL,
  },
  {
    appName: APP_KEYS.ROUNDS,
    label: t('navbar.appMenu.rounds'),
    url: envConfig.ROUNDS_URL,
  },
  {
    appName: APP_KEYS.WHITEBOARD,
    label: t('navbar.appMenu.whiteboard'),
    url: envConfig.WHITEBOARD_URL,
  },
];

export const MAIN_MENU_OPTIONS: PHMainMenuProps['options'] = [
  {
    label: t('navbar.mainMenu.profile'),
    value: 'profile',
    url: envConfig.PROFILE_URL,
  },
  {
    label: t('navbar.mainMenu.admin'),
    value: 'admin',
    url: envConfig.ADMIN_URL,
  },
  {
    label: t('navbar.mainMenu.help'),
    value: 'help',
    url: envConfig.HELP_CENTER_URL,
  },
];

export const getMainMenuOptions = (
  hasUserAccessToAdmin: boolean
): PHMainMenuProps['options'] => {
  if (hasUserAccessToAdmin) {
    return MAIN_MENU_OPTIONS;
  }
  return MAIN_MENU_OPTIONS.filter((option) => option.value !== 'admin');
};
